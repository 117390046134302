import Hero from "../../components/Hero";
import single from '../../assets/Spa.jpg'
import Spa from "../../components/Spa";


export default function SpaPage(){
    return(
       <>
        <Hero imgUrl={single}  deletTitle={true}/>
        <Spa/>
       </>
    )
}