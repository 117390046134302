import React, { useState, useEffect } from 'react';
import { useLanguage } from '../hooks/useContexts';
import { useIsWeb } from '../hooks/isWeb';

import boda from "../assets/event/bodas/boda.jpg"
import boda1 from "../assets/event/bodas/boda1.jpg"
import boda2 from "../assets/event/bodas/boda2.jpg"
import boda3 from "../assets/event/bodas/boda3.jpg"
import boda4 from "../assets/event/bodas/boda4.jpg"
import boda5 from "../assets/event/bodas/boda5.jpg"

import event from "../assets/event/event/eventos.jpg"
import event1 from "../assets/event/event/eventos1.jpg"

export default function SliderEvent() {
  const { languageData } = useLanguage();
  const isWeb = useIsWeb();

  const services = [
    {
      imgs: [boda,boda1, boda2, boda3, boda4, boda5],
      type: languageData.evento.formPage.wedding,
      urlPage: languageData?.routes.singleRoom,
      detail: languageData.evento.formPage.weddingInfo
    },
    {
      imgs: [event, event1, event,event1,event,event1],
      type: languageData.evento.formPage.guest,
      urlPage: languageData?.routes.doubleRoom,
      detail: languageData.evento.formPage.guestInfo
    }
  ];

  return (
    <section>
      <div className="absolute bg-crema left-0 w-full flex justify-center items-center">
        <div className={`z-50 rounded-lg bg-crema flex flex-row ${isWeb ? "mt-[-5%]" : "mt-[-40%]"} bg-crema text-center`}>
          <div className="container rounded bg-white max-w-[1200px] mx-auto py-16">
          <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center mb-3">{languageData.evento.formPage.title}</h2>
            <p className=" px-6 font-light text-base sm:text-lg font-mont ">
            {languageData.evento.formPage.subtitle}
            </p>
            <div className="grid lg:grid-cols-2 font-mont py-8 px-8">
              {services.map((service, index) => (
                <CarouselCard key={index} service={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const CarouselCard = ({ service }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % service.imgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [service.imgs.length]);

  return (
    <div className="container-card-room sm:p-2 pb-4">
      <div className="relative w-full h-80  rounded-lg mb-4">
        {service.imgs.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`${service.type} ${index + 1}`}
            className={`absolute w-full h-[400px] object-cover transition-opacity duration-1000 ${index === currentImage ? 'opacity-100' : 'opacity-0'}`}
          />
        ))}
      </div>
      <div className="relative w-4/5 ml-[10%] z-50 bg-white p-4 shadow-md  text-center">
        <h3 className="text-vino text-lg font-mont">{service.type}</h3>
        <p className="text-gray-600 font-mont">{service.detail}</p>
        
      </div>
    </div>
  );
};
