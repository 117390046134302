import React, { createContext, useEffect, useState } from 'react'

export const LanguageContext = createContext()

const LanguageProvider = ({ children, lang }) => {
    const [language, setLanguage] = useState('ES')
    const [languageData, setLanguageData] = useState(null)
    

    const contextValue = {
        languageData, language, setLanguage
    }

    useEffect(() => {
        const ctrl = new AbortController()
        const fetchLanguageData = async () => {
            try {
                import(`../utils/constants/languages/${language?.toLowerCase()}`)
                    .then(data => { setLanguageData(data) })
            } catch (error) {
                console.error('Error fetching language data:', error);
            }
        };
        fetchLanguageData();
        return () => ctrl.abort()
    }, [language])


    if (!languageData) return <>Loading...</>
    return (
        <LanguageContext.Provider value={contextValue}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider
