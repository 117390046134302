/*import { useIsWeb } from "../hooks/isWeb";*/
import { useLanguage } from "../hooks/useContexts";
import single from '../assets/Habitaciones.jpg'
export function AddresRoom() {
  /*let isWeb = useIsWeb();*/
  const { languageData } = useLanguage()
  
  return (
    <>

        <div className="bg-crema">
  <div className="flex justify-center items-center bg-crema p-8">
    <div className="flex flex-col md:flex-row bg-white py-5 px-5 rounded items-start md:items-center w-full max-w-6xl">
      
      <div className="w-full md:w-1/2 order-1 md:order-1 mt-6 md:mt-0 ">
        <div className="relative">
          <img src={single} alt="Habitaciones Purmamarca" className="rounded-lg shadow-lg" />
        </div>
      </div>
      
      <div className="w-full md:w-1/2 order-2 md:order-1 px-4">
        <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4 pt-4">{languageData.rommCard.title}</h2>
        <p className="text-xl font-mont text-zinc-500 mb-4 sm:mx-4">{languageData.rommCard.subTitle}</p>
        <p className="text-zinc-700 font-mont mb-6 sm:mx-4">
        {languageData.rommCard.text} </p>
        <a href="/rooms" className=" font-semibold mt-2 sm:mx-4">
        {languageData.rommCard.seeMore}
              </a>
      </div>
    </div>
  </div>
</div>

    </>
  );
}
