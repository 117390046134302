import React from 'react'
import evento from '../assets/evento1.jpg'
 /*import { useLanguage } from '../hooks/useContexts' */
import Form from '../components/Form'

export default function BannerEventos()  {
   /*const { languageData } = useLanguage() */

  return (
<div className="relative">
  <img src={evento} alt="Imagen grande" className="w-full h-[650px]" />
  <div className="absolute top-10 sm:right-10 p-4 mx-4 bg-white shadow-md">
    {/* Tus campos de formulario aquí */}
    <Form/>
  </div>
</div>
  )
}