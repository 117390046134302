import React from 'react';
import { useIsWeb } from '../../hooks/isWeb';

export default function ConstructionBanner({ onNavigate, title, detail, letter }) {
  const isWeb = useIsWeb();

  return (
    <div  className={`z-50 rounded-lg w-[60%] bg-white   flex flex-row ${isWeb?"mt-[-5%]":"mt-[-40%]"}  text-center`}>
      <div className="bg-white rounded-lg p-8 mx-auto max-w-md">
        <h2 className="font-libre-baskerville italic text-3xl font-bold text-vino mb-4">
          {title || 'Title..' }
        </h2>
        <p className="text-vino-800 mb-6">
                {detail || 'Detail..'}
                </p>
        <button
          onClick={onNavigate}
          className="bg-vino text-white py-2 px-4 rounded-lg hover:bg-vino-700"
        >
          {letter || 'Letter..'}
        </button>
      </div>
    </div>
  );
}
