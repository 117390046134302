
 import { useLanguage } from "../hooks/useContexts";
import single from '../assets/Spa.jpg'
export function AddresSpa() {

  const { languageData } = useLanguage()
  return (
    <>

        <div className="bg-crema">
  <div className="flex justify-center items-center bg-crema p-8">
    <div className="flex flex-col md:flex-row bg-white py-5 px-5 rounded items-start md:items-center w-full max-w-6xl">
      
      <div className="w-full md:w-1/2 order-1 md:order-1 mt-6 md:mt-0 ">
        <div className="relative">
          <img src={single} alt="Spa en Purmamarca" className="rounded-lg shadow-lg" />
        </div>
      </div>
      
      <div className="w-full md:w-1/2 order-2 md:order-1 px-4">
      <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4 pt-4">{languageData.spaCard.title}</h2>
        <p className="text-xl font-mont text-zinc-500 uppercase mb-4 sm:mx-4">{languageData.spaCard.subTitle}</p>
        <p className="text-zinc-700 mb-6 font-mont sm:mx-4">
     {languageData.spaCard.text}  </p>
        <a href={languageData?.routes.spa} className=" font-semibold uppercase mt-2 sm:mx-4">
                {languageData.spaCard.seeMore}
              </a>
      </div>
    </div>
  </div>
</div>

    </>
  );
}