import React from "react";
import simple from "../assets/single.webp";
import suite from "../assets/suite.webp";
import casa from "../assets/casacard.jpg";
import doble from "../assets/doble.webp";
import { useIsWeb } from "../hooks/isWeb";
import { useLanguage } from "../hooks/useContexts";
export default function Rooms({ isCardInfo }) {
  
    let isWeb = useIsWeb();
    const { languageData } = useLanguage()
    console.log(languageData?.room[0],'languageData');
    


  const room = [
    {
      img: simple,
      type: "Twin room",
      urlPage: languageData?.routes.singleRoom,
    },
    {
      img: doble,
      type: "Double room",
      urlPage:languageData?.routes.doubleRoom,
    },
    {
      img: suite,
      type: "Suite room",
      urlPage: languageData?.routes.suiteRoom,
    },
    {
      img: casa,
      type: "House",
      urlPage: languageData?.routes.ejectSuiteRoom,
    },
  ];

  return (
    <section >
      {/* cardUnderHero */}
      <div className="absolute  bg-crema  left-0 w-full  flex justify-center items-center ">
        <div
                className={`z-50 rounded-lg  bg-crema flex flex-row ${isWeb?"mt-[-5%]":"mt-[-40%]"} bg-crema text-center`}
        >
           {/*infoRoom */}
      <div className="container rounded bg-white max-w-[1200px] mx-auto py-16">
        <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center  mb-3">
         {languageData?.room.title}
        </h2>
        <div className="flex items-center justify-center"></div>
      {/*   <div className="grid  py-10">
          <p className="text-vino  font-mont text-base sm:text-lg px-4 text-start ml-5 ">
          {languageData?.room.detail}
          </p>

        </div> */}

        <div className="grid  lg:grid-cols-2 ">
          {room.map((rooms, index) => {
            return (
              <div
                className="container-card-room "
                key={index}
                style={{ padding: "25px" }}
              >
                <div className="mx-2 mb-2 object-cover relative after:absolute cursor-pointer after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-b from-black/25 to-black/75">
                  <img src={rooms.img} alt="" style={{ zIndex: 2 }} />
                </div>

                <div className="relative cursor-pointer top-[-10%] w-4/5 ml-[10%] z-50 bg-white p-4 shadow-md text-center">
                  <h3 className="text-gray-800 text-lg font-semibold">
                    {rooms.type}
                  </h3>
               {/*    <p className="text-gray-600">{rooms.type}</p> */}
                  <button className="mt-2 bg-vino text-white  uppercase px-4 py-2 rounded-md hover:bg-vino-dark">
                    <a href={rooms.urlPage}> { languageData?.room.seeDetail } </a>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
        </div>
      </div>
      
    
    </section>
  );
}
