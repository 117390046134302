import Hero from "../../components/Hero";
import eventoinfo from '../../assets/eventoinfo.webp'
import React from "react";
import SliderEvent from "../../components/SliderEvent";


export default function Eventos(){
    

 
    return(
        <>  
            <Hero imgUrl={eventoinfo} deletTitle={true} />
            <SliderEvent/>
        </>
    )
} 