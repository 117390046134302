import DetailRoom from "../../components/DetailRoom";
import Hero from "../../components/Hero";
import { useLanguage } from "../../hooks/useContexts";
import suite from "../../assets/suiteheader.jpg"
export default function SuiteRoom(){
    const title='Suite Room';
    const { languageData } = useLanguage()
  
    const retrunPage = languageData?.routes.rooms;
    return(
    <>
    <Hero imgUrl={suite} retrunPage={retrunPage}  deletTitle={true} />
    <DetailRoom title={title} />
    </>
    )
    }
