
import Hero from '../../components/Hero'
import About from '../../components/About';
/* import Habitaciones from '../../components/Rooms' */
import { AddresSpa } from '../../components/AddresSpa'
import Gmap from '../../components/Gmap'
import Footer from '../../components/Footer'
import { AddresRoom } from '../../components/AddresRoom';
import AddresResto from '../../components/AddresResto'; 
import BannerEventos from '../../components/BannerEventos'

export default function Home(){
    return(
        <>
    <Hero/>
    <About/>

   {/*  <RestoInfo/> */}

    <AddresRoom/>
    <AddresResto/>
    <AddresSpa/>
    <BannerEventos/>
    {/* <Habitaciones/> */}


    {/* <Galeria/> */}
    <Gmap/>
    <Footer/>
    </>
    )
}