import React from 'react'

export default function Gmap () {
  return (
        <section>
       <iframe 
          title="Mapa de Manantial del Silencio" 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.4747859381775!2d-65.50366538492555!3d-23.741568384586486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x941b49ca833a1183%3A0x897de17cd130cd87!2sHotel%20El%20Manantial%20del%20Silencio!5e0!3m2!1ses-419!2sar!4v1649340503994!5m2!1ses-419!2sar" 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade" 
          className="min-h-[480px] w-full"
        ></iframe>
        
        </section>


  )
}
