import Hero from "../../components/Hero";
import DetailRoom from "../../components/DetailRoom";
import { useLanguage } from "../../hooks/useContexts";
import doble from "../../assets/doble.webp";

export default function DobleRoom() {
  const title = "Doble Room";
  const { languageData } = useLanguage()
  
  const retrunPage = languageData?.routes.rooms;

  return (
    <>
      <Hero imgUrl={doble} retrunPage={retrunPage} deletTitle={true} />
        
      <DetailRoom title={title} />
    </>
  );
}


/* import simple from "../assets/single.webp";
import suite from "../assets/suite.webp";
import casa from "../assets/casa.webp"; */