/* import React, { useEffect, useState } from 'react'; */
/* import calandria from "../assets/logocalandria.png"; */
import { useIsWeb } from "../hooks/isWeb";
import { useLanguage } from "../hooks/useContexts";

/* import food from "../assets/food/food.jpg";
import food1 from "../assets/food/food1.jpg";
import food3 from "../assets/food/food3.jpg";
import food4 from "../assets/food/food4.jpg";
import food5 from "../assets/food/food5.jpg";
import food6 from "../assets/food/food6.jpg";
import food7 from "../assets/food/food7.jpg"; */
import ConstructionModal from './hooksComponents/ContainerModal';

export default function AboutResto({ isCardInfo }) {
  const { languageData } = useLanguage();
  const isWeb = useIsWeb();

/*   const room = [
    { img: food },
    { img: food1 },
    { img: food3 },
    { img: food4 },
    { img: food5 },
    { img: food6 },
    { img: food7 },
  ];
 */
 /*  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % room.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [room.length]); */
  
  const handleNavigate = () => {
    // Aquí puedes agregar la lógica para redirigir a la carta digital
    window.location.href = 'https://odibo.app/menu/manantialdelsilencio'; // Ajusta la URL según tu ruta
  };
  
  console.log(languageData?.resto.titleSection,'languageData?.resto.titleSection');

  return (
    <section className={`bg-crema min-h-[640px] mx-auto px-3 py-16 ${isWeb ? "min-w-[700px]" : "max-w-[700px]"}`}>
      
     {/*  {isCardInfo && (
        <div className="absolute left-0 w-full bg-crema flex justify-center items-center">
          <div className={`z-50 ${isWeb ? "w-[85%]" : "w-full"} rounded-lg bg-white flex flex-row mt-[-104px] text-vino text-center`}>
            <div className="p-4">
              <h2 className={`font-libre-baskerville italic text-5xl font-bold ${!isWeb ? "mb-3" : "mb-3"}`}>
                {languageData?.resto.title}
              </h2>
              <p className={`text-vino-800 ${isWeb ? "text-xs" : "text-m"} sm:text-base mx-auto w-9/10`}>
                {languageData?.resto.detail}
              </p>
              <p className={`text-vino-800 ${isWeb ? "text-xs" : "text-m"} sm:text-sm mx-auto w-4/5 mt-2.5`}>
                {languageData?.resto.availability}
              </p>

              <div className={`container max-w-[1200px] mx-auto grid md:grid-cols-2 gap-14 ${!isWeb ? (isCardInfo ? "mt-[80%]" : "") : (isCardInfo ? "mt-40" : "")}`}>
                <div className="flex flex-col justify-center items-start">
                  <img src={calandria} className={`${isWeb ? 'ml-[85%] -mt-10 mb-20' : 'mb-5 -mt-40 ml-24'} h-40`} alt="Calandria" />
                  <p className="font-body text-justify text-base sm:text-lg text-vino">
                    {languageData?.resto.calandria}
                  </p>
                </div>
                <div className="">
                  <div className={`${isWeb ? "mt-[32%]" : ""}`}>
                    <p className="font-body text-justify text-base sm:text-lg text-vino">
                      {languageData?.resto.calandria2}
                    </p>
                  </div>
                </div>
              </div>

              <div className="relative md:ml-[30%] mt-[5%] ms:w-auto md:w-[600px] h-[500px] overflow-hidden rounded-sm mb-4">
                {room.map((rooms, index) => (
                  <img
                    key={index}
                    src={rooms.img}
                    className={`absolute ms:w-auto  md:w-[600px] h-[500px] object-cover transition-opacity duration-1000 ${index === currentImage ? 'opacity-100' : 'opacity-0'}`}
                    alt={`room ${index}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
       */}
        <div  className="absolute z-50 left-0 w-full flex justify-center items-center">
       <ConstructionModal title={languageData?.resto.titleSection} detail={languageData?.resto.detailSection} letter={languageData?.resto.letterSection} onNavigate={handleNavigate} />
       </div>
      
    </section>
  );
}
