import { useState, useEffect } from 'react';

/**
 * It returns a value and a setter function that will update the value in localStorage
 * @Author Facundo Toffolo Pasquini <facundotp@randev.com.ar>
 * @Params key: string initialValue: T | (() => T)
 * @Hook useLocalStorage
 * @returns - A function that takes a key and an initialValue
 *   - The function returns an array with two values:
 *     - The first value is the current value of the state
 *     - The second value is a function that updates the state
 *   - The function also has a side effect that saves the state to localStorage
 * @Example
 *  - const [value, setValue] = useLocalStorage<string>('key', 'initialValue');
 *  - setValue('newValue');
 */
const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        const jsonValue = localStorage.getItem(key)
        if (jsonValue != null) return JSON.parse(jsonValue)
        if (typeof initialValue === 'function') return (initialValue)()
        return initialValue
    })
    useEffect(() => {
        const ctrl = new AbortController()
        localStorage.setItem(key, JSON.stringify(value))
        return () => ctrl.abort()
    }, [key, value])
    return [value, setValue]
}

export default useLocalStorage