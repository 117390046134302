import DetailRoom from "../../components/DetailRoom";
import Hero from "../../components/Hero";
import { useLanguage } from "../../hooks/useContexts";
import casa from "../../assets/casa.webp";
export default function EjectSuiteRoom(){
    
   const title="Ejectu Suite Room "
   const { languageData } = useLanguage()
  
   const retrunPage = languageData?.routes.rooms;

     return (
        <>
          <Hero imgUrl={casa} retrunPage={retrunPage}  deletTitle={true} />
            
            <DetailRoom title={title} />
        </>
      );
    }
    
