import Hero from "../../components/Hero";
import AboutResto from "../../components/Resto";
import imgHeroResto from "../../assets/restomain.jpg"

export default function RestoPAge() {
  const url = imgHeroResto;
  

  return (
    <>
      <Hero imgUrl={url} deletTitle={true} />
      <AboutResto isCardInfo={true} />
    </>
  );
}
