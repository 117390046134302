import Hero from "../../components/Hero";
import Rooms from "../../components/Rooms";
import imgHeroRomm from "../../assets/room.jpg"
export default function RoomPage(){
    const url = imgHeroRomm
// Ver como puedo hacer para replicar el zoom que se aplica en navbar del room in page llao
    return(
        <>
            <Hero imgUrl={url} deletTitle={true}/>
            <Rooms  isCardInfo={true} />
            
        </>
    )
}