import DetailRoom from "../../components/DetailRoom";
import Hero from "../../components/Hero";
import { useLanguage } from "../../hooks/useContexts";
import simple from "../../assets/single.webp";
export default function SingleRoom(){
  const { languageData } = useLanguage()
  
  const title=' Single Room';
  const retrunPage = languageData?.routes.rooms;
    return(
      <>
      <Hero imgUrl={simple} retrunPage={retrunPage} deletTitle={true}/>
      <DetailRoom title={title} />
      </>
        
    )
}

