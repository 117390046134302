import React from "react";

import { useLanguage } from '../hooks/useContexts';
const Form = () => {
  const { languageData } = useLanguage();
 // console.log(languageData.evento.formEvent,'languageData.evento');
      return(
        <form className="w-full max-w-lg"  action="https://formsubmit.co/manzurmariaflorencia@gmail.com" method="POST"  >
        <div className="flex flex-wrap -mx-3 ">
        <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4">{languageData.evento.formEvent.title}</h2>
          <div className="w-full md:w-1/2 px-3  md:mb-0">
            
            <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="grid-first-name">
            
            </label>
            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" name='nombre' type="text" placeholder={languageData.evento.formEvent.namePlaceholder}/>
            <p className="text-white text-xs italic"></p>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="grid-last-name">
            
            </label>
            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" name='apellido' type="text" placeholder={languageData.evento.formEvent.lastNamePlaceholder}></input>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 ">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="grid-password">
              Email
            </label>
            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" name='email' type="email" placeholder={languageData.evento.formEvent.emailPlaceholder} />
            <p className="text-gray-600 text-xs italic"></p>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="grid-password">
            
            </label>
            <textarea className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" name='message' id="message"></textarea>
            <p className="text-white text-xs italic"></p>
          </div>
        </div>
        <div className="flex items-center justify-between">
        <button className="bg-vino py-4 px-4 font-bold text-white">
          {languageData.evento.formEvent.send}
        </button>
        <a href="/events" className="text-vino pr-4 text-lg underline">{languageData.evento.formEvent.seeMore}</a>

        </div>  
        
        
        
        <input type="hidden" name="_next" value="https://randev.com.ar/"></input>  {/* modificar conla la url del home */}
         <input type="hidden" name="_captcha" value="false"></input>
        
      </form>
  
      )
  
  }
  export default Form;